
@include media-breakpoint-up(sd) {
    .container{
        max-width: 970px;
    }
}
    
@include media-breakpoint-up(md) {
    body{
        background-color: $verylightgrey;
    }
    .container{
        max-width: 970px;
    }
    .grey_dsktp_bg{
        background-color: $verylightgrey;
    }

    .footer{
        left:50%;
        width:60%;
        transform: translateX(-50%);
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .fixed-bottom{
        .btn-bottom-ctnr{
            position: relative;
            width: 100%;
            max-width: 720px;
            left:50%;
            transform: translateX(-50%);
        }
    }
    

    //Campaign page
    .campaign_nav button {
        border: none;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        width: calc(50% - 20px);
        display: inline-block;
        margin: 10px;
        padding: 25px 15px;
        text-align: center;
        text-indent: -10%;
        &:hover{
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);  
        }
        &:after{
            top: 25px;
        }
        &.error:before, &.success:before{
            top: 25px;
            left: 15px;
        }
    }

    #bk_campaign .header .col{
        background-size: contain;
    }

    .footer-cta {    
        width: 100%;
        max-width: 720px;
        left: 50%;
        transform: translateX(-50%);
    }
    .modal_cta.fixed-bottom{
        border-top:none;
    }
    .slick-slide > div{
        height:450px;
        img{
            height:100%;
            object-fit: contain
        }
     }

    //Chats
    .absolute-bottom{
        width: 100%;
        max-width: 720px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 1px solid $verylightgrey;
        border-right: 1px solid $verylightgrey;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
    #bk_chat{
        .header {
            h2{
                text-align: center;
            }
        }
    }

    //Campaign page
    .steps_ctnr {
        .steps{
            .step_item{
                &:hover{
                    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);  
                }
            }
        }
    }

    //campaigns
    #bk_campaign{
        .header {
            .col{
                background-image: none !important;
                .desktop_images{
                    max-height: 275px;
                    overflow: hidden;
                    display: block;
                   img{
                    width:33.33%;
                   }
                }
            }
            .col_ad{
                background-repeat: no-repeat;
                background-color:$beige;
                background-size: cover;
                background-position: center;
                .desktop_images{
                    max-height: 275px;
                    overflow: hidden;
                    display: block;
                }
            }
        }
        &.fixed_header{
            .header{
                position: relative;
                width: 100%;
                top:0;
                left:0;
                height:$header_height;
                margin:0 !important;
            }
            .content{
                padding-top:75px;
            }
        }
    }


}




@include media-breakpoint-up(lg) { 
    .footer{
        width:50%;
   }
   .campaign_nav button {
        width: calc(25% - 20px);
   }
}





@include media-breakpoint-up(xl) { 
    .footer{
        width:40%;
   }
}





@include media-breakpoint-up(xxl) { 
    .footer{
        width:30%;
   }
}