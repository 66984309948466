
#root{
    &.body_ios{
        .header_icon{
            height: 150px;
        }
        #bk_chat{
            .header{
                padding-top:75px !important;
            }
            .go_back_btn{
                top:auto;
                bottom:15%;
                transform:none;
            }
        }
        #bk_apply{
            .modal_header{
                padding-top:50px !important;
                min-height: 50px !important;
                padding-bottom: 0 !important;
            }
            .close_btn{
                position: relative;
                top:auto;
                bottom:15px;
            }
        }
        #bk_campaigns,#bk_deals{
            .header{
                padding-top:75px !important;
            }
        }
        .footer,.modal_cta, .footer-cta{
            padding-bottom: 20px !important;
            height:80px;
            .alert{
                margin-top:-40px;
            }
        }
        
        #erroroffline{
            margin-top:50px;
        }
    }
}