.form-label {
    display: block;
    font-weight: 500;
	&.required:after {
		content: "*";
		margin-left: 0.25rem;
		color: #d63939;
	}
}
.form-label-popovers {
    display: block;
	&.required .popovers{
		
		margin-left: 0.55rem;
	}
	&.required:after {
		content: "*";
		margin-left: -0.55rem;
		color: #d63939;
	}
}

.form-check-label{	
	&.required:after {
		content: "*";
		margin-left: 0.25rem;
		color: #d63939;
	}
}

.form-fieldset {
    padding: 1rem;
    margin-bottom: 1rem;
    background: $verylightgrey;
    border: 1px solid $lightgrey;
    border-radius: 4px;
}

/* ****************
Radio
************** */
.form-selectgroup {
    
	.form-selectgroup-item {
		display: block;
		position: relative;
		margin: 0 .5rem .5rem 0;
		&.disabled{
			color: $lightgrey;
		}
		.form-selectgroup-input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			opacity: 0;
			&:checked+.form-selectgroup-label {
				z-index: 1;
				color: $primary;
				background: rgba(32,107,196,.04);
				border-color: $primary;
			}
		}
		.form-selectgroup-label {
			position: relative;
			min-width: calc(1.4285714em + .875rem + 2px);
			margin: 0;
			padding: .4375rem .75rem;
			font-size: .875rem;
			/*line-height: 0.4;*/
			color: $grey;
			background: #fff;
			text-align: center;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			border: 1px solid #dadcde;
			border-radius: 3px;
			transition: border-color .3s,background .3s,color .3s;
			text-align: left;
			padding: 1rem 1rem;
			color: inherit;
		}
		.form-selectgroup-check {
			display: inline-block;
			width: 1rem;
			height: 1rem;
			border: 1px solid rgba(101,109,119,.24);
			vertical-align: middle;
		}
		.form-selectgroup-input[type=radio]+.form-selectgroup-label .form-selectgroup-check {
			border-radius: 50%;
		}
		.form-selectgroup-input:checked+.form-selectgroup-label .form-selectgroup-check {
			background-color: $primary;
			background-repeat: repeat;
			background-position: center;
			background-size: 1rem;
			border-color: $primary;
		}
		.form-selectgroup-input[type=radio]:checked+.form-selectgroup-label .form-selectgroup-check {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
		}
	}
}

.steps_ctnr{
	.steps{
		padding:15px 15px 15px 25px;
		.step_item{
			position: relative;
			border-radius: 15px;
			background-color:$white;
			border:1px solid $lightgrey;
			h3{
				font-size: 1.2em;
			}
			.step_digit,.step_checkmark{
				position: absolute;
				left:-15px;
				top:50%;
				transform: translateY(-50%);
				background-color:$white;
				border:1px solid $lightgrey;
				text-align: center;
				color:$lightgrey;
				border-radius: 50%;
				width:30px;
				height:30px;
			}
			.step_checkmark{
				display: none;
			}
			&.step_done{
				border:2px solid $primary;
				color:$primary;
				background-color: #e3fdfd;
				.step_digit{
					display:none;
				}
				.step_checkmark{
					display: block;
					border:2px solid $primary;
					color:$white;
					background-color:$primary;
				}
				.ico{
					background-image: url('/Assets/img/ico_termine.png');
				}
			}
			&.step_next{
				border:2px solid $lightpink;
				.step_digit{
					border:2px solid $lightpink;
					font-weight: bold;
					color:$lightpink;
				}
				.ico{
					background-image: url('/Assets/img/ico_suivant.png');
				}
			}
			.icon{
				color:$lightgrey;
			}
			.ico{
				background-size: 40px;
				background-repeat: no-repeat;
				background-position: center;
				width:45px;
				height:45px;
			}
		}
	}
}