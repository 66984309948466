
.footer {
  position:fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: $white;
  /*border-top-left-radius: $radius-15;
  border-top-right-radius: $radius-15;*/
  border-top:1px solid $verylightgrey;
}
.navbar{
    border-top: 1px solid transparent;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.selectors, .block{
  height:100%;
  width:100%;
}

.selectors button{
  color: $black;
  text-transform: inherit;
  border: 0;
  border-radius: 0;
  width:25%;
  margin-left: 0;
  text-align: center;
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*&:first-child{
    border-top-left-radius: $radius-15;
  }
  &:last-child{
    border-top-right-radius: $radius-15;
  }*/
  /*&.btn-center{
    .selector-holder{
      border: 5px solid $primary;
      background-color:$white;
      color: $primary;
      border-radius: 50%;
      width: 75px;
      height: 75px;
      display: block;
      margin: 0 auto;
      margin-top: -30px;
      text-align: center;
    }
  }*/
  svg{
    margin: auto;
    display: block;
    transform: scale(1.6);
  }
  &.button-active, &:hover, &:active, &:focus{
    color: $primary !important;
    box-shadow: none !important;
    /* suppression de l'actif
    &:after{
      content:'';
      position: absolute;
      left:50%;
      bottom:5px;
      transform: translate(-50%, -50%);
      width:5px;
      height:5px;
      background-color: $primary;
      border-radius: 50%;
    }*/
  }
  .selector-holder{
    display: flex;
    flex-direction: column;
    justify-content: center!important;
    font-size:0.7em;
    font-family: 'AndesLight' !important;
    img{
      height:25px;
      margin: 2px auto 4px auto;
    }
  }
}

.selectors button:focus{
  border:0;
  outline: 0;
  box-shadow: 0 0 0 0px;
}

.active{
  display: flex;
  flex-direction: column;
  
}

.selector-holder {
  .pills{
    position: absolute;
    top:5%;
    right:17%;
    width:17px;
    height:17px;
    padding-top:1px;
    text-align: center;
    font-size: 0.95em;
    border-radius: 50%;
    font-family: 'AndesLight' !important;
    &.pink{
      background-color: $pink;
      color:$white;
    }
    &.orange{
      background-color: $secondary;
      color:$white;
    }
  }
  span{
    font-size: 0.8rem;
  }
}



.button-inactive{
  color: #000;
}

.dropdown-menu.simple-drop {
  border-radius:0px;
  margin-top: 10px;
}